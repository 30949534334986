import React from 'react';
import {  FaGithubAlt } from "react-icons/fa";
// import {HiOutlineMail} from "react-icons/hi";
import {FiLinkedin} from "react-icons/fi";

function Contact() {
  return(
    <div className='contact'>
      <h1 className="thankyou">THANK YOU</h1>
      <div className="contactDetail">
        <p>{"for taking the time to get to know me :)"}</p>
        <p className="contactlogo">Think we could build something amazing together? Let's start a conversation!</p>
        <div className="links">
          {/* <p>➤ LinkedIn</p><p>➤ GitHub</p><p>➤ szetodonna2@gmail.com</p> */}
          <div className='email'>
              <button className="social"><a className="social" href="https://github.com/donnalikestocode" target="_blank" rel="noreferrer noopener"><FaGithubAlt /></a></button>
              <button className="social"><a className="social" href="https://www.linkedin.com/in/donnaszeto/" target="_blank" rel="noreferrer noopener"><FiLinkedin/></a> </button>
          </div>
          <div className="email">
            {/* <button className="social"><HiOutlineMail/> </button> */}
            <p>szetodonna2@gmail.com</p>
          </div>

        </div>
      </div>

    </div>
  );
}

export default Contact;