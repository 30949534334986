let projectDetail = [
  {
    "id":1,
    "url": "/thumbnails/Moments.png",
    "title": "MOMENTS",
    "info": "A full stack iOS app that helps users practice mindfulness by capturing Gratitudes and Moments",
    "frameworks": ["React-Native", "Expo", "Express", "MongoDB"]
  },

  {
    "id":2,
    "url": "/thumbnails/Freetime.png",
    "title": "FREETIME",
    "info": "A full stack web app that finds mutual free time among friends and schedules events between friends.",
    "frameworks": ["React", "Next.js", "Chakra UI", "Firebase"]
  },

  {
    "id":3,
    "url": "/thumbnails/Spotify.png",
    "title": "AUTOMATE SPOTIFY",
    "info": "A full stack web app that automates the process of copying songs from a user’s Youtube playlist to a new Spotify playlist",
    "frameworks": ["HTML", "Express"]
  },

  {
    "id":4,
    "url": "/thumbnails/SEC.png",
    "title": "HOUSE-TULLY",
    "info": "Developed and optimized backend system powering ecommerce web app Nomad-Widow",
    "frameworks": ["Express", "AWS", "PostGres"]
  },

  {
    "id":5,
    "url": "/thumbnails/FEC.png",
    "title": "NOMAD-WIDOW",
    "info": "Developed and optimized backend system powering ecommerce web app Nomad-Widow",
    "frameworks": ["React", "CSS"]
  }


]

export default projectDetail