import React from 'react';
import Carousel from './Carousel.jsx'

const Projects = () => {


  return(

    <div className='projects'>
      <ol start='2'>
      <li className='project-title'><span><h3>Projects</h3></span></li>
      </ol>
      <Carousel />
    </div>
  );
}

export default Projects;