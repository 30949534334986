import React from 'react';
import {  FaHeart } from "react-icons/fa";
import {useNavigate } from 'react-router-dom';

export const CarouselItem = ({item, width}) => {

  const navigate = useNavigate();

  const toProjectDetails=()=>{
    navigate('/projects',{state:{id:item.id}});
      }

  return(
    <div className="carousel-item" style ={{ width: width}}>
      <div className="carousel-item-inner">
        <button className='carousel-img-button' onClick={() =>{toProjectDetails()}}>
           <img className="carousel-img" src={item.url} alt="carouselImg"></img>
           </button>
        <div className="carousel-text">
          <div className ="carousel-name">
            <div>{item.title}</div>
            <button className='heart'><FaHeart/></button>
          </div>
          <div className ="carousel-info">{item.info}</div>
          <div className="carousel-framework">
            {item.frameworks.map((item)=>{
              return <li className="carousel-list">{item}</li>
            })}
          </div>
        </div>
      </div>
    </div>
  )
};


