import React from 'react';

function Intro() {
  return(
    <div className='intro-container'>
      <div className='intro-container-inner'>
        <p className='hi'>Hi, my name is</p>
        <div className='contain-name'>
          <h1>DONNA SZETO</h1>
          <div className="ellipse1"> </div>
          <div className="ellipse2"> </div>
        </div>
        <h2>welcome to my coding journey</h2>
        <div className="intro-text">
          <p>I’m a software engineer who is passionate about building web and mobile applications that empower individuals and organizations to achieve their full potential </p>
          <a href="https://www.linkedin.com/in/donnaszeto/" target="_blank" rel="noreferrer noopener" class="button">CONTACT ME </a>
        </div>
      </div>
    </div>
  )
}

export default Intro;