import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function Nav() {
  return(
    <ul className="nav-container">
      <Link to='home#about'><li className="nav-container-li"><span>About</span></li></Link>
      <Link to="home#projects"><li className="nav-container-li"><span>Projects</span></li></Link>
      <Link to="home#contact"><li className="nav-container-li"><span>Contact</span></li></Link>
      < Link to="/resume" className="nav-container-div button">Resume </Link>
    </ul>
  );
}

export default Nav;