let projectInfo = [
  {
    "id": 1,
    "title": "MOMENTS",
    "description": "A full stack iOS app that helps users practice mindfulness by capturing Gratitudes and Moments",
    "detail": ["Architected and implemented user interface and experience; styled page typography and colors", "Integrated with a document-based data store to persist images and posts of gratitudes and moments", "Designed RESTful backend server for storing and displaying posts"],
    "url": "/details/Moments.png"
  },

  {
    "id": 2,
    "title": "FREETIME",
    "description": "A full stack web app that finds mutual free time among friends and schedules events between friends",
    "detail": ["Collaborated with a team of 7 engineers to plan weekly goals with consideration of potential roadblocks and proactively learned new skills like component library integration and Firebase Authentication to help meet team deadlines and client requests", "Debugged npm package integration for compatibility with latest Next.js version to display interactive calendar", "Implemented event scheduling algorithm to reactively render newly created events on calendar display"],
    "url": "/details/FreeTime.png"
  },

  {
    "id": 3,
    "title": "Automate Spotify",
    "description": "A full stack web app that automates the process of copying songs from a user’s Youtube playlist to a new Spotify playlist",
    "detail": ["Integrated with Youtube and Spotify APIs to access user’s Youtube playlists, search Spotify songs, then create Spotify playlists with those songs", "Used documentation to learn how to work with OAuth 2.0 which involves requesting and refreshing Access tokens for providing authorization to Spotify’s REST services"],
    "url": "/details/spotify.png"
  },

  {
    "id": 4,
    "title": "House-Tully",
    "description": "Developed and optimized backend system powering ecommerce web app Nomad-Widow",
    "detail": ["Employed an ETL process to transfer mass data from CSV files to populate Postgres database", "Deployed local testing through k6 and cloud testing through loader.io to determine bottlenecks and scaling needs to improve speed of backend services","Manually scaled horizontally from one server to three servers while using a Nginx Load Balancer with least connected load balancing strategy and caching strategies which resulted in a max throughput of 4000 RPS with a 60% latency improvement across two routes with a 0% error rate"],
    "url": "/details/SEC.png"
  },

  {
    "id": 5,
    "title": "Nomad-Widow",
    "description": "An ecommerce web app where users can purchase, review, and favorite clothing products",
    "detail": ["Designed app’s homepage and overview of products consisting of custom carousel and star rating components", "Implemented zoom and pan algorithm to allow users to enlarge and move through product images"],
    "url": "/details/FEC.png"
  },


]

export default projectInfo;