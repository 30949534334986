import React from 'react';
import Nav from './Nav.jsx';
import ProjectDetails from './ProjectDetails.jsx';
import { Route, Routes} from 'react-router-dom'
import Home from './Home.jsx'
import Resume from './Resume'


function App() {


  // const handleClick = ({target}) =>{
  //   console.log(target.value)
  // }

  return (
      <div>
        <Nav/>
        <Routes>
            <Route exact path ="" element ={<Home/>} />
            <Route exact path ="/home" element ={<Home/>} />
            <Route exact path ="/resume" element={<Resume/>} />
            <Route path="/projects" element={<ProjectDetails/>} />
          </Routes>
    </div>
  );
}

export default App;
