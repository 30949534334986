import React from 'react';
// import about from '../about.jpg'

function About() {
  return(
    <div className='about'>
      <div>
        <ol>
          <li className='about-title'><span><h3>About Me</h3></span></li>
        </ol>
          <div className='about-detail'>
            <div className='image'></div>
            <img src="/images/about.jpg" alt="about" className="aboutImage"></img>
            <div className="about-text">
              <p>
                Hi there! I'm Donna - a software engineer with a passion for crafting web applications that support people in their journey of self-improvement and advancement.
                </p>
              <p>
                A year ago, I made the bold decision to leave my job and pursue my passion in software engineering. My journey began with a chance encounter - a YouTube video showcasing the use of Spotify API to develop a program that seamlessly transfers songs from my YouTube playlist to Spotify. This sparked a newfound passion in me and ignited my imagination with the limitless possibilities of what I could create with code. The thrill of turning ideas into reality through coding was truly exhilarating.
                </p>
              <p>
              In 2022, I completed the HackReactor coding bootcamp, where I gained an extensive amount of knowledge and a newfound appreciation for coding. Since then, I have created several applications, but my favorite among them is Moments. This app aims to encourage people to practice gratitude and self-love.

              My experience has instilled in me a strong desire to collaborate with others in exploring the limitless potential of software development. I believe that by working together, we can create masterpieces that positively impact the world. :)
                </p>
            </div>

          </div>
      </div>
    </div>

  );
}

export default About;