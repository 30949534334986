import React, {useEffect, useRef} from 'react';
import Carousel from '@itseasy21/react-elastic-carousel';
import ProjectInfo from '../data/projectInfo.js';
import {useLocation} from 'react-router-dom';


function ProjectDetails() {
  const location = useLocation();
  const items = ProjectInfo;
  const refInput = useRef(null)

  useEffect(() => {
    // console.log('THIS IS IT', location.state.id)
    // console.log(Object.keys(refInput))
    // console.log(refInput.current.goTo(1))
    refInput.current.goTo(location.state.id-1)
  });

  return(
    <div className='project-carousel'>
    <Carousel ref={refInput}>
       {items.map(item =>
        <div className ='project-carousel-item'>
          <div className='project-carousel-text'>
            <h1 className='project-carousel-title' key={item.id}>{item.title}</h1>
            <div className='project-carousel-disc' key={item.id}>{item.description}</div>
            <ul className='project-carousel-list'>
              {item.detail.map((item)=>{
                return <li className="project-carousel-list">{item}</li>
              })}
            </ul>
          </div>
          <img className='project-carousel-img' src={item.url} alt="largeCarouselImg"></img>
        </div>
      )}
    </Carousel>
  </div>
  )
}

export default ProjectDetails

