import React, {useState} from 'react';
import { CarouselItem} from "./CarouselItem";
import projectDetail from '../data/projectDetail';
import { FaPauseCircle, FaStepForward, FaStepBackward } from 'react-icons/fa'

const Carousel = () => {

  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= items.length) {
      newIndex = items.length -1;
    }

    setActiveIndex(newIndex);
  }

  const items = projectDetail;
  return(
    <div className="carousel-whole">
      <div className="carousel">
        <div
          className="inner"
          style={{ transform: `translate(-${activeIndex * 100}%)`
        }}
        >
          {items.map((item)=>{
            return <CarouselItem item={item} width={"100%"}/>
          })}
        </div>
      </div>

      <div className="carousel-buttons">
        <button className="back" onClick={()=>{
          updateIndex(activeIndex -1)
        }}><FaStepBackward/></button>
        <button className="play"> <FaPauseCircle/> </button>
        <button className="forward" onClick={()=>{
          updateIndex(activeIndex +1)
        }}> <FaStepForward/> </button>
      </div>
    </div>
  );
};

export default Carousel;